<template>
  <section id="card">
    <div class="page">
      <add-card @add-card="addCard" />
    </div>
  </section>
</template>

<script>
/* PAS UTILISÉ - À SUPPRIMER ? */
import AddCard from '@/views/cards/AddCard.vue';

export default {
  name: 'card',

  components: {
    AddCard,
  },

  methods: {
    addCard() {
      // Redirection vers la liste des cartes
      this.$router.push({ name: 'cards' });
    },
  },
};
</script>
<style lang="sass">
#card

  .page

    @include page

    h1
      color: $white
      margin-bottom: 30px

      svg
        margin-right: 10px
</style>
